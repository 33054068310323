body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

/* to remove scrollbar in windows */
html {
  margin: 0;
  padding: 0;
  overflow: visible;
  overflow-x: hidden;
  /* remove firefox scrollbar on html*/
  scrollbar-width: none;
  /* background-color: #f0f0f0; */
}

* {
  scrollbar-width: none;
}

/* to remove scrollbar in windows */

.Select-menu-outer {
  z-index: 1000 !important;
}
