/* import { grey } from @material-ui/core/colors; */

.link {
  cursor: pointer;
  color: #5571cc;
  padding: 5px 10px;
  font-size: 14px;
}

.tdSender {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 05px 10px;
  font-size: 16px;
  font-weight: bold;
}

.sentDate {
  padding: 05px 10px;
  font-size: 12px;
  text-align: right;
}

.tblGeneral {
  width: 100%;
  font-size: 12;
  border-spacing: 0;
  padding-bottom: 10;
  color: #424242;
}

.tblGeneralReply {
  width: 100%;
  font-size: 12;
  border-spacing: 0;
  padding-bottom: 10;
  color: #424242;
  background-color: #f4f4f4;
  border: 1px solid #dcdcdc;
}

.tblGeneralReply {
}

.tdMessage {
  padding: 05px 10px;
}

.tblTitle {
  width: 100%;
  font-size: 12;
  border-spacing: 0;
  border-bottom: 1px solid #999;
  padding-bottom: 5;
  color: #424242;
}

.trLeft {
  font-weight: 600;
  padding-top: 10;
  font-size: 12;
}

.trRight {
  text-align: right;
}

.tdButtons {
  padding: 05px 10px;
}

.editFeesButton {
  float: right;
  margin-top: 20;
}

.svg_icons {
  font-size: 20px;
  margin-left: 5px;
}
