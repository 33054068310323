.case-warning-containar {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 20px;
  margin: 2px 0;
  font-size: 14px;
  font-weight: 500;
  background-color: #fcedd3;
  border-left: 20px solid #ffa726;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  box-sizing: content-box;
}
