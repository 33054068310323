.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps__rail-x {
  height: 15px;
  bottom: 0;
}
.ps__rail-x,
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  position: absolute;
}
.ps__rail-y {
  width: 15px;
  right: 0;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y,
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: 0.9;
}
.ps__thumb-x {
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  bottom: 2px;
}
.ps__thumb-x,
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  position: absolute;
  min-height: 16px;
}
.ps__thumb-y {
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
}
.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .ps {
    overflow: auto !important;
  }
}
.list-dk-scroll-view .ps__thumb-x {
  bottom: 0;
}
.list-dk-scroll-view .ps__rail-y {
  left: auto !important;
}
.list-dk-scroll-view .ps__rail-x,
.list-dk-scroll-view .ps__rail-y {
  z-index: 1 !important;
}
.list-dk-scroll-view.perfect-list {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.list-dk-scroll-view.perfect-list .scroll-content {
  position: relative;
  display: block;
  width: 100%;
  height: inherit;
  max-height: inherit;
}
.list-dk-scroll-view.perfect-list .scroll-content.scroll-always > .ps__rail-x,
.list-dk-scroll-view.perfect-list .scroll-content.scroll-always > .ps__rail-y {
  opacity: 0.65 !important;
}
.list-dk-scroll-view.perfect-list .scroll-content.scroll-wheel-stop {
  overscroll-behavior: contain;
}
.list-dk-scroll-view.perfect-list.ps {
  border: 0 !important;
}
.list-dk-scroll-view.perfect-list.ps.scroll-always > .ps__rail-x,
.list-dk-scroll-view.perfect-list.ps.scroll-always > .ps__rail-y {
  opacity: 0.65 !important;
}
.list-dk-scroll-view.perfect-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}
.list-dk-scroll-view.perfect-table thead {
  display: block;
}
.list-dk-scroll-view.perfect-table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.list-dk-scroll-view.perfect-table tbody {
  display: block;
  position: relative;
  overflow: hidden;
}
.list-dk-scroll-view.perfect-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.list-dk-scroll-view.perfect-table tbody.ps {
  border: 0 !important;
}
.list-dk-scroll-view.perfect-table tbody.scroll-always > .ps__rail-x,
.list-dk-scroll-view.perfect-table tbody.scroll-always > .ps__rail-y {
  opacity: 0.65 !important;
}
.list-dk-scroll-view.perfect-table tbody.scroll-wheel-stop {
  overscroll-behavior: contain;
}
